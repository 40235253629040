import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const RandomColors = ({
  count = 1000
}) => {
  const [els, setEls] = React.useState([]);

  const randomHexColor = () => {
    return '#' + ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  };

  const regenEls = () => {
    const newEls = [];

    for (let i = 0; i < count; i++) {
      newEls.push(<div style={{
        height: 16,
        width: 16,
        backgroundColor: randomHexColor()
      }} />);
    }

    setEls(newEls);
  };

  React.useEffect(() => regenEls(), []);
  return <div onClick={regenEls} style={{
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer'
  }}>
      {els}
    </div>;
};
export const _frontmatter = {};
const layoutProps = {
  RandomColors,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi from MDX-landia`}</h1>
    <p>{`Click the diagram to regenerate the colors.`}</p>

    <RandomColors mdxType="RandomColors" />
    <p>{`yeah!`}</p>
    <p>{`The color component is taken from the `}<a parentName="p" {...{
        "href": "https://johno.com/randomness-and-color/"
      }}>{`blog post of MDX creator John
Otander`}</a>{` exploring randomness and
color.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      